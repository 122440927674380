import axios from "axios";
import authHeader from "./auth-header";
import AuthService from "./auth.service";
import { config } from "../config";

class CrmGraphics {
  dataLeadsByStatus() {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/leadsByStatus`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  dataLeadsByStatusAndSellers() {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/leadsByStatusBySellers`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  dataSalesSellers(year, month) {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/salesBySellers/${year}/${month}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  dataSalesClientType(year, month) {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/salesByClientType/${year}/${month}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  dataSalesProbability(year, month) {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/salesByProbability/${year}/${month}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }

  dataSalesCategories(year, month) {
    return axios
      .get(`${config["apiUrl"]}crm/graphics/salesByCategory/${year}/${month}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
  leadsBySaleDate(queryStringParametersObj) {
    const params = new URLSearchParams(queryStringParametersObj);
    return axios
      .get(`${config["apiUrl"]}crm/graphics/getLeadsSaleDate?${params}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status == 200) {
          return response.data;
        }
        return null;
      })
      .catch((error) => {
        if (error.response.status == 401) {
          AuthService.logout();
          window.location = "/login";
        }
        console.log("ERROR:: ", error.response.data);
        return null;
      });
  }
}
export default new CrmGraphics();
